<template>
    <el-dialog
        :title="$t('clients.edit_damage')"
        :visible.sync="modalVisible"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @open="modalOpen"
        @closed="$emit('closed')"
    >
        <div class="flex justify-center my-5 px-12">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules" class="w-full">
                <el-form-item :label="$t('clients.client')">
                    <el-input v-model="formData.client" disabled />
                </el-form-item>

                <el-form-item :label="$t('clients.employee')">
                    <el-input v-model="formData.employee" disabled />
                </el-form-item>

                <el-form-item :label="$t('clients.status')" class="w-full">
                    <el-select v-model="formData.status" class="w-full">
                        <el-option :value="0" :label="$t('clients.open')" />
                        <el-option :value="1" :label="$t('clients.solved')" />
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('clients.description')" prop="description" class="w-full">
                    <el-input
                        v-model="formData.description"
                        type="textarea"
                        :rows="3"
                    />
                </el-form-item>

                <el-form-item :label="$t('clients.amount')" prop="amount" class="w-full">
                    <money v-model="formData.amount" class="el-input__inner" v-bind="money" />
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('loading.edit_damage')" @click="update">{{ $t('common.update') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import { Money } from 'v-money';

Vue.use(Money);

export default {
    components: {
        Money,
    },

    props: {
        modalVisible: {
            type: Boolean,
        },
        damageData: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            uuid:     null,
            formData: {
                client:      '',
                employee:    '',
                description: '',
                status:      0,
                amount:      0,
            },
            money: {
                decimal:   ',',
                thousands: '',
                precision: 2,
                masked:    true,
            },
            rules: {
                amount:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                description: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        modalOpen() {
            this.uuid = this.damageData.uuid;
            this.formData = {
                status:      this.damageData.status,
                amount:      this.damageData.amount / 100,
                description: this.damageData.description,
                client:      `${this.damageData.client_name} ${this.damageData.client_surname}`,
                employee:    `${this.damageData.employee_name} ${this.damageData.employee_surname}`,
            };
        },

        async update() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('loading.edit_damage');

            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/damages/${this.uuid}`, {
                    status:      this.formData.status,
                    amount:      parseInt(this.formData.amount.replace(',', ''), 10),
                    description: this.formData.description,
                });

                this.$wait.end('loading.edit_damage');
                this.$notify.success({ title: 'Success' });
                this.$emit('refresh');
                this.closeModal();
            } catch (error) {
                this.$wait.end('loading.edit_damage');
            }
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
